//social media
import {Mail, PhoneCall, Instagram} from "react-feather";
import eitaaIcon from '@assets/images/icons/eitaa.png';
import soroushIcon from '@assets/images/icons/soroush.png';

export const DETAILS = {
    name: "شرکت روزآوران",
    shortName: "شرکت روزآوران",
    enName: "rooz avaran ",
    domain: "https://www.rozavaran.ir",
    description: "محصولات پروتئینی با طعم و کیفیت بی نظیر را با روزآوران تجربه کنید و سلامتی خود را حفظ کنید",
    shortAbout: "  ما به عنوان یک تولید کننده محصولات پروتئینی، از جمله سوسیس، کالباس، همبرگر و کباب لقمه، تلاش می کنیم تا نیازهای تغذیه ای شما را با بهترین کیفیت و خدمات حرفه ای برآورده کنیم. محصولات پروتئینی ما، تحت نظر یک کادر مجرب و متخصص در زمینه تولید و انتخاب مواد اولیه با کیفیت، تولید می شوند. هدف ما ارائه محصولاتی است که علاوه بر طعم عالی و لذیذ بودن، حاوی مقادیر مناسبی از پروتئین و مواد مغذی باشند.\n" +
        "\n" +
        "                            تمامی محصولات ما دارای استانداردهای بهداشتی لازم هستند و به صورت دقیق تحت نظر ناظرین بهداشتی بازرسی می شوند. اطمینان حاصل کنید که شما بهترین و سالم‌ترین محصولات را دریافت می کنید. ما به شدت به سلامتی و رضایت مشتریان خود اهمیت می‌دهیم و تمام تلاشمان را می‌کنیم تا محصولاتی را به بازار عرضه کنیم که نه تنها طعمی عالی داشته باشند، بلکه از لحاظ مقادیر غذایی و سلامتی هم به استانداردهای بالا پاسخگو باشند.\n",
    veryShortAbout: "  ما به عنوان یک تولید کننده محصولات پروتئینی، از جمله سوسیس، کالباس، همبرگر و کباب لقمه، تلاش می کنیم تا نیازهای تغذیه ای شما را با بهترین کیفیت و خدمات حرفه ای برآورده کنیم. محصولات پروتئینی ما، تحت نظر یک کادر مجرب و متخصص در زمینه تولید و انتخاب مواد اولیه با کیفیت، تولید می شوند. هدف ما ارائه محصولاتی است که علاوه بر طعم عالی و لذیذ بودن، حاوی مقادیر مناسبی از پروتئین و مواد مغذی باشند.\n" +
        "\n" +
        "                            تمامی محصولات ما دارای استانداردهای بهداشتی لازم هستند و به صورت دقیق تحت نظر ناظرین بهداشتی بازرسی می شوند. اطمینان حاصل کنید که شما بهترین و سالم‌ترین محصولات را دریافت می کنید. ما به شدت به سلامتی و رضایت مشتریان خود اهمیت می‌دهیم و تمام تلاشمان را می‌کنیم تا محصولاتی را به بازار عرضه کنیم که نه تنها طعمی عالی داشته باشند، بلکه از لحاظ مقادیر غذایی و سلامتی هم به استانداردهای بالا پاسخگو باشند.\n",
    mobiles: [
        '09128943501',
        '09399754505'
    ],
    phones: [
        '02633311847',
        '02633300528'
    ],
    email: [],
    postalCode: [""],
    address: "استان البرز، شهرکرج، مهرشهر، بلوار ارم بین خ اول و دوم نبش کوچه ایثار گران",
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3235.4429858858052!2d50.892328941676524!3d35.813605576904166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQ4JzQ5LjAiTiA1MMKwNTMnMzcuOSJF!5e0!3m2!1sen!2sde!4v1704270050120!5m2!1sen!2sde",
    socialMedia: [
        /*{
            title: 'واتس اپ',
            value: '09125342338',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=+9125342338&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },*/
        // {
        //     title: 'تلگرام',
        //     value: '09123456789',
        //     name: 'telegram',
        //     link: 'https://t.me/+9123456789',
        //     icon: <i className="lab la-telegram-plane"/>,
        //     isPrimary: true
        // },
        {
            title: 'اینستاگرام',
            value: 'rooz_avaran',
            name: 'instagram',
            link: 'https://www.instagram.com/rooz_avaran/',
            icon: <i className="lab la-instagram"/>,
            isPrimary: true
        },
        // {
        //     title: 'ایمیل',
        //     value: 'Volvotak@gmail.com',
        //     name: 'email',
        //     link: 'mailto:Volvotak@gmail.com',
        //     icon: <i className="las la-envelope"></i>,
        //     isPrimary: true
        // },
        // {
        //     title: 'تماس',
        //     value: '09133377432',
        //     name: 'phone',
        //     link: 'tel:989133377432',
        //     icon: <PhoneCall/>,
        //     isPrimary: true
        // },
        /*{
            title: 'آپارات',
            value: 'Alo_aghsat',
            name: 'aparat',
            link: 'https://www.aparat.com/Alo_aghsat',
            icon: <SiAparat size={25}/>,
            isPrimary: true
        },
        {
            title: 'تماس',
            value: '02636634893',
            name: 'phone',
            link: 'tel:+982636634893',
            icon: <PhoneCall/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09331074475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09332094475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'سروش پلاس',
            value: '09330324475',
            name: 'soroushPlus',
            link: '',
            icon: <img src={soroushIcon} alt='سروش'/>,
            isPrimary: false
        },
        {
            title: 'ایتا',
            value: '09331074475',
            name: 'eitaa',
            link: '',
            icon: <img src={eitaaIcon} alt='ایتا'/>,
            isPrimary: false
        },
        {
            title: 'ایتا',
            value: '09332094475',
            name: 'eitaa',
            link: '',
            icon: <img src={eitaaIcon} alt='ایتا'/>,
            isPrimary: false
        },
        {
            title: 'ایتا',
            value: '09330324475',
            name: 'eitaa',
            link: '',
            icon: <img src={eitaaIcon} alt='ایتا'/>,
            isPrimary: false
        }*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    relatedLinks: [
        /* {
             title: 'بانک ملت',
             link: 'https://bankmellat.ir/',
             icon: <img src={MellatBankImage} alt='mellat-bank'/>,
         */
    ],
    "supportDescription": "",
    "workingHours": [],
    "__choose among these__": "",
    "enamadLink": true,
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
